import { useContext } from "react"
import useTranslations from "hooks/useTranslations"
import { LocaleContext, LocationContext } from "contexts"
import cc from "classcat"
import { siteCountry } from "utils/env"
import Instagram from "images/icons-social/instagram-round.svg"
import Facebook from "images/icons-social/facebook-round.svg"
import X from "images/icons-social/x-round.svg"
import LinkedIn from "images/icons-social/linkedin-round.svg"
import Button from "components/buttons/Button"
import { getFooterLogoByCountry, getCustomFooterLogo } from "utils/locale"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  LinkedinIcon,
  FacebookIcon,
  TelegramIcon,
  XIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share"
import countries from "../../../../config/i18n"

const locales = countries[siteCountry] || countries.fi

const FooterButton = ({ buttonStyle, to, label }) => {
  return (
    <div>
      <Button
        className="shadow-lg"
        bgStyle={buttonStyle || "black"}
        link={to}
        label={label}
      />
    </div>
  )
}

const SocialMediaLink = ({ Icon, link, name }) => {
  return (
    <a href={link} className="flex " target="_blank" rel="noreferrer">
      <span className="sr-only text-zinc-800 hover:text-zinc-700">{name}</span>
      <Icon className="w- xs:w-10" />
    </a>
  )
}

const Footer = ({ className, buttonStyle }) => {
  const {
    location: { pathname, href },
  } = useContext(LocationContext)
  const { locale } = useContext(LocaleContext)
  const socialMediaLinks = [
    {
      name: "LinkedIn",
      link: locales[locale].social.linkedin,
      Icon: LinkedIn,
    },
    {
      name: "Instagram",
      link: locales[locale].social.instagram,
      Icon: Instagram,
    },
    {
      name: "Facebook",
      link: locales[locale].social.facebook,
      Icon: Facebook,
    },
    {
      name: "X",
      link: locales[locale].social.x,
      Icon: X,
    },
  ]

  const shareButtons = [
    {
      button: EmailShareButton,
      icon: EmailIcon,
    },
    {
      button: FacebookShareButton,
      icon: FacebookIcon,
    },
    {
      button: LinkedinShareButton,
      icon: LinkedinIcon,
    },
    {
      button: TwitterShareButton,
      icon: XIcon,
    },
    {
      button: WhatsappShareButton,
      icon: WhatsappIcon,
    },
    {
      button: TelegramShareButton,
      icon: TelegramIcon,
    },
    {
      button: WorkplaceShareButton,
      icon: WorkplaceIcon,
    },
  ]

  const { footerContact, footerContactButton, sharePage, checkPage } =
    useTranslations()

  const footerLinks = [
    {
      to: "/#contact",
      label: footerContactButton,
      id: "contactLink",
    },
  ]

  const Logo = getCustomFooterLogo(pathname, locale) || getFooterLogoByCountry()

  return (
    <footer
      className={cc([
        "relative",
        className,
        {
          "bg-kkm-green text-white": !className,
        },
      ])}
    >
      <div className="relative py-16 lg:pb-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className=" flex flex-col space-y-8">
            <Logo className="mx-auto w-28 md:mx-0" />
            <p className="m-0 whitespace-pre-line text-center font-display text-base leading-6 md:text-left">
              {footerContact}
            </p>
            <div className="mr-auto mt-12 flex w-full flex-col justify-between space-y-6 border-zinc-400 lg:flex-row lg:space-x-6 lg:space-y-0">
              <div className="flex flex-col items-center space-y-4 xs:flex-row xs:space-x-4 xs:space-y-0">
                {footerLinks.map((link) => (
                  <FooterButton
                    key={link.id}
                    {...link}
                    buttonStyle={buttonStyle}
                  />
                ))}
                <div className="inline-flex space-x-3">
                  {socialMediaLinks.map((someLink) => (
                    <SocialMediaLink {...someLink} key={someLink.name} />
                  ))}
                </div>
              </div>
              <div className="flex flex-col items-center justify-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
                <p className="m-0 font-display">{sharePage}:</p>
                <div className="items-center lg:flex">
                  {shareButtons.map((b) => (
                    <b.button
                      url={href}
                      title={checkPage}
                      className="mr-2 last:mr-0"
                    >
                      <b.icon
                        size={40}
                        round
                        bgStyle={{ fill: "currentColor" }}
                        iconFillColor="#aaaaaa"
                      />
                    </b.button>
                  ))}
                </div>
              </div>
            </div>

            <p className="m-0 mt-4 text-center text-xs leading-6 md:order-2 md:mt-0 md:text-left">
              &copy; {`${locales[locale].defaultTitle}`} - All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
